<template>
  <ion-page class="page scrollable">
    <TopBar />
    <div class="bg-transparent">
      <h1 class="title p-3">Got a code?</h1>
      <div class="h-100 px-2 pb-3 container">
        <form @submit.prevent="onSubmit">
          <ion-input
            v-model.trim="code"
            class="c-input code d-inline-block"
            placeholder="Enter code here"
            autocapitalize="off"
            required
          />
          <ion-button class="enter submit-btn d-inline-block" type="submit">Enter</ion-button>
        </form>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native';
import { enterGiveaway } from '@/shared/actions/users';
import TopBar from '@/shared/components/TopBar.vue';

const code = ref('');

const onSubmit = async () => {
  if (!code.value) await toast.show('Enter a code.', 'nonative', 'medium');
  try {
    const data = await enterGiveaway(code.value.trim());
    await toast.show('You have been entered in the giveaway!', 'nonative', 'primary');
  } catch (e: any) {
    if (e?.response) await toast.show(e.response.data, 'nonative', 'danger');
    else await toast.show('Could not submit code', 'nonative', 'danger');
  }
  code.value = '';
}
</script>
<style lang="sass" scoped>
.title
  text-align: center
  font-size: 24px
  color: #214163
.code
  width: 250px
  margin-bottom: 0
.enter
  margin-left: 0.25rem
  height: 44px
  margin-bottom: 13px
  width: 100px
</style>
